<template>
  <div>
    <v-toolbar dense class="elevation-1">
      <v-icon color="red" class="text-h4">mdi-human-male-board</v-icon>
      <v-toolbar-title
        :style="
          $vuetify.breakpoint.smAndUp
            ? 'letter-spacing: 0.5rem'
            : 'letter-spacing: 0.2rem'
        "
        class="ml-3 font-weight-bold"
        >{{ $t('gestor.gestor_title') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="darkpink" rounded dark v-bind="attrs" v-on="on">
            <v-icon left> mdi-plus-thick </v-icon>
            {{ $t('botoes.botao_novo') }}
          </v-btn>
        </template>
        <v-card class="rounded-lg" elevation="5">
          <v-card-title class="darkblue white--text mb-4">
            <span class="text-h5">{{ $t('botoes.botao_novogestor') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form">
                <v-row>
                  <v-col cols="12" sm="6" md="7">
                    <v-text-field
                      color="darkblue"
                      v-model="formManager.name"
                      :rules="[rules.required]"
                      :label="$t('label.label_nome')"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="5">
                    <v-text-field
                      color="darkblue"
                      v-model="formManager.user"
                      :rules="[rules.required]"
                      :label="$t('label.label_usuario')"
                      @change="verifyUser(formClient.username)"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      color="darkblue"
                      :label="$t('label.label_email.email_obr')"
                      :rules="[rules.required, rules.email]"
                      v-model="formManager.email"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-if="!editMode">
                    <v-text-field
                      color="darkblue"
                      v-model="formManager.password"
                      :rules="[rules.required, rules.length(4)]"
                      :label="$t('label.label_senha.label_senhaob')"
                      :type="showPassword ? 'text' : 'password'"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12">
                    <v-select
                      item-color="darkblue"
                      color="blue"
                      v-model="selectedSchool"
                      :items="schools"
                      item-text="name"
                      item-value="id"
                      :label="$t('regisResponsavel.painel.escola')"
                      :no-data-text="$t('regisResponsavel.resgistrocadastro')"
                      :rules="[rules.option]"
                      multiple
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>*{{ $t('regras.regra_obrigatorio') }}</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darkpink" rounded text @click="closeRegister()">
              {{ $t('botoes.botao_cancelar') }}
            </v-btn>
            <v-btn color="darkpink" rounded text @click="saveBtn()">
              {{ $t('botoes.botao_salva') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card
        class="rounded-lg py-3 error--text"
        style="border-left: 12px inset red"
      >
        <v-card-title>
          <v-icon left large color="error">mdi-alert-circle</v-icon>
          {{ $t('alerta.alerta_title') }}
        </v-card-title>
        <v-card-text class="black--text">
          {{ $t('alerta.alerta_excluir.excluir1') }}
        </v-card-text>
        <v-card-actions class="d-flex justify-end pr-4">
          <v-btn
            color="error"
            dark
            rounded
            class="px-3"
            @click="deleteItemConfirm()"
          >
            {{ $t('botoes.botao_excluir') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-card class="elevation-0 mt-1">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          color="darkblue"
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
          :style="
            $vuetify.breakpoint.smAndUp ? 'max-width: 30%' : 'max-width: 60%'
          "
        >
        </v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="Managers"
        :search="search"
        :footer-props="{
          'items-per-page-options': [20, 45, 60, 100, -1],
          'items-per-page-all-text': 'Todos',
          'items-per-page-text': 'Itens por página',
          'show-first-last-page': true,
        }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            rounded
            outlined
            small
            @click="editItem(item)"
            color="darkblue"
            class="px-2 mx-1"
          >
            <v-icon small class="mr-1">mdi-pencil</v-icon>
            Editar
          </v-btn>
          <v-btn
            rounded
            outlined
            small
            @click="deleteItem(item)"
            color="error"
            class="px-2 mx-1"
          >
            <v-icon small class="mr-1">mdi-delete</v-icon>
            Excluir
          </v-btn>
        </template>
      </v-data-table>
    </v-card> -->
    <v-row class="ma-4">
      <v-col
        cols="12"
        md="6"
        lg="4"
        v-for="manager in Managers"
        :key="manager.id"
      >
        <v-card class="rounded-lg py-4 px-2" style="height: 100%">
          <div
            class="
              d-flex
              flex-column
              align-center
              justify-space-around
              text-center
            "
            style="height: 100%; min-height: 25vh"
          >
            <div v-if="manager.avatar !== null">
              <v-img
                width="90"
                height="90"
                class="rounded-circle"
                :src="manager.avatar"
              >
              </v-img>
            </div>
            <div>
              <div class="font-weight-bold" style="font-size: 1.2rem">
                {{ manager.name }}
              </div>
              <div class="mb-4 font-weight-medium" style="font-size: 0.9rem">
                {{ manager.user }}
              </div>
            </div>
            <div style="font-size: 0.9rem">
              <div>
                <span class="font-weight-bold"> {{ $t('student.student_label.label7') }} </span>
                {{ manager.email }}
              </div>
              <div>
                <span class="font-weight-bold"> {{ $t('student.student_minhainformacao.informacao5') }} </span>
                {{ manager.phone }}
              </div>
            </div>
            <div class="mt-6">
              <v-btn
                rounded
                outlined
                small
                icon
                @click="editItem(manager)"
                color="darkblue"
                class="px-2 mx-1"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                rounded
                outlined
                small
                icon
                @click="deleteItem(manager)"
                color="error"
                class="px-2 mx-1"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="errorSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="error"
      outlined
      text
    >
      <v-icon
        :large="$vuetify.breakpoint.smAndUp ? true : false"
        color="error"
        left
        >mdi-close-circle
      </v-icon>
      <span class="black--text text-subtitle-1">
        {{ $t('alerta.alerta_erro') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="errorSnackbar = false"
          rounded
          color="error"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="successSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="success"
      outlined
      text
    >
      <v-icon
        :large="$vuetify.breakpoint.smAndUp ? true : false"
        color="success"
        left
        >mdi-check-circle
      </v-icon>
      <span class="black--text text-subtitle-1">
        {{ $t('alerta.alerta_sucesso') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="successSnackbar = false"
          rounded
          color="success"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped></style>

<script>
const url = process.env.VUE_APP_API_URL;
const axios = require("axios");

const token = "Baerer " + localStorage.getItem("accessToken");

export default {
  data() {
    return {
      dialog: null,
      formManager: {},
      search: "",
      headers: [
        { text: this.$t('login.login_usuario.usuario5'), value: "name" },
        { text: this.$t('login.login_painel.login1'), value: "user" },
        { text: this.$t('label.label_email.email'), value: "email" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      Managers: [],
      editedIndex: -1,
      dialogDelete: null,
      successSnackbar: false,
      errorSnackbar: false,
      showPassword: false,
      selectedSchool: [],
      schools: [],
      editMode: false,
      rules: {
        email: (v) =>
          !!(v || "").match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        maxLength: (len) => (v) =>
          (v || "").length <= len ||
          `${this.$t('regras.regra_senhaMax.senha1')} ${len} ${this.$t('regras.regra_senhaMax.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_opcao'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        user: (v) =>
          !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
          `${this.$t('regras.regra_user')}`,
        exist: () => !this.userExisting || this.$t('regras.regra_existeuser'),
        none: true,
      },
      userExisting: false,
    };
  },
  methods: {
    async verifyUser(res) {
      let response = await axios.get(`${url}/clients/user?user=${res}`, {
        headers: {
          authorization: token,
        },
      });
      if (response.data != null) {
        this.userExisting = true;
      } else {
        this.userExisting = false;
      }
    },
    getManager() {
      axios
        .get(`${url}/managersForClient`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.Managers = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getSchools() {
      axios
        .get(`${url}/schools`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.schools = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async saveBtn() {
      let validation = this.$refs.form.validate();

      if (validation) {
        if (!this.editMode) {
          try {
            await this.registerManager(this.selectedSchool);
            setTimeout(() => {
              this.successSnackbar = true;
            }, 1000);
          } catch {
            this.errorSnackbar = true;
          }
        }
        if (this.editMode) {
          try {
            await this.updateManager(this.formManager.id, this.selectedSchool);
            setTimeout(() => {
              this.successSnackbar = true;
            }, 1000);
          } catch {
            this.errorSnackbar = true;
          }
        }
        this.$refs.form.reset();
        this.dialog = false;
      }
    },
    registerManager(schoolsIds) {
      axios
        .post(`${url}/managersForClient`, this.formManager, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          const data = {
            schoolId: schoolsIds,
            managerId: res.data.success.id,
          };

          axios
            .post(`${url}/managerInscriptions`, data, {
              headers: {
                authorization: token,
              },
            })
            .then(() => {
              this.getManager();
            });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateManager(id, schoolsIds) {
      axios
        .put(`${url}/managers/${id}`, this.formManager, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          const data = { schoolId: schoolsIds, managerId: id };
          axios
            .post(`${url}/managerInscriptions`, data, {
              headers: {
                authorization: token,
              },
            })
            .then(() => {
              this.getManager();
            });

          this.getManager();
        })
        .catch((error) => {
          console.error(error);
          alert("Error");
        });
    },
    deleteItem(item) {
      this.editedIndex = item.id;
      this.formManager = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      axios
        .delete(`${url}/managers/${this.editedIndex}`, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.closeDelete();
          this.getManager();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.formManager = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeRegister() {
      this.$refs.form.reset();
      this.dialog = false;
      this.editMode = false;
    },

    async editItem(item) {
      let res = await axios.get(
        `${url}/managerInscriptions/?manager=${item.id}`,
        {
          headers: {
            authorization: token,
          },
        }
      );
      res.data.forEach((element) => {
        this.selectedSchool.push(element.school.id);
      });

      this.editedIndex = item.id;
      this.formManager = Object.assign({}, item);
      this.dialog = true;
      this.editMode = true;
    },
  },
  created() {
    this.getManager();
    this.getSchools();
  },
};
</script>
