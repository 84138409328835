<template>
  <v-app id="inspire">
    <v-app-bar
      app
      :height="$vuetify.breakpoint.mobile ? '46px' : '35px'"
      class="darkblue"
      dark
      clipped-left
    >
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.mdAndDown"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-spacer />
      {{ $t('admin.admin_administrador') }}
      <v-spacer />
      <v-btn disabled icon> </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      clipped
      class="blue-grey darken-4"
      dark
      :mini-variant="$vuetify.breakpoint.lgAndUp"
      :expand-on-hover="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list nav dense>
        <v-list-item-group color="blue">
          <v-list-item
            v-for="(item, i) in menu"
            :key="i"
            @click="
              page = item.title;
              $vuetify.breakpoint.smAndUp ? '' : (drawer = !drawer);
            "
          >
            <v-list-item-icon>
              <v-icon> {{ item.icon }} </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> {{ item.title }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <div class="logoutStyle">
          <v-list-item @click="profileDialog = true">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> {{ clientInfo.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-dialog v-model="logoutDialog" persistent max-width="320">
      <v-card elevation="15" class="rounded-xl py-4 px-2">
        <v-img src="../assets/HelloCircle2.svg" width="85px" class="mx-auto" />
        <v-card-title style="font-size: 1.1rem" class="mt-n3">
          {{ $t('admin.admin_sair.sair1') }}
        </v-card-title>
        <v-card-text style="font-size: 0.9rem" class="mt-n2">
          {{ $t('admin.admin_sair.sair2') }}
        </v-card-text>
        <v-card-actions class="mt-n2">
          <v-row no-gutters justify="center">
            <v-col cols="12" class="pb-3">
              <v-btn
                rounded
                color="darkblue"
                large
                dark
                block
                @click="logout()"
              >
                {{ $t('botoes.botao_sair') }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                outlined
                rounded
                large
                color="darkblue"
                dark
                block
                @click="logoutDialog = false"
              >
                {{ $t('botoes.botao_cancelar') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="firstAccess"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
      width="40%"
    >
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text">
          {{ $t('label.label_senha.senha_redefinir') }}
        </v-card-title>
        <v-card-text class="pt-6">
          <v-form ref="editPass">
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  :label="$t('label.label_celular.celular_inserir')"
                  color="darkblue"
                  append-icon="mdi-cellphone"
                  v-mask="'(##) #########'"
                  outlined
                  v-model="phone"
                  :rules="[rules.required, rules.phoneLength(13)]"
                  class="px-2"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="px-2">
                <v-text-field
                  color="darkblue"
                  :label="$t('label.label_senha.senha_nova')"
                  outlined
                  :rules="[rules.required, rules.length(4)]"
                  :type="showPassword1 ? 'text' : 'password'"
                  :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword1 = !showPassword1"
                  :placeholder="$t('label.label_senha.senha_inserasenha')"
                  v-model="newPassword"
                />
              </v-col>
              <v-col cols="12" sm="6" class="px-2">
                <v-text-field
                  color="darkblue"
                  :label="$t('label.label_senha.senha_confirmar')"
                  outlined
                  :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
                  :type="showPassword2 ? 'text' : 'password'"
                  :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword2 = !showPassword2"
                  :placeholder="$t('label.label_senha.senha_confirmarsua')"
                  v-model="confirmPassword"
                />
              </v-col>
              <v-row no-gutters>
                <v-checkbox v-model="enabled" color="darkblue">
                  <template v-slot:label>
                    <div>
                      {{ $t('hometeacher.hometeacher_termo.termo1') }}
                      <a
                        target="_blank"
                        href="https://next.vuetifyjs.com/en/components/checkboxes/"
                        class="darkblue--text"
                        @click.stop
                      >
                        {{ $t('hometeacher.hometeacher_termo.termo2') }}
                      </a>
                    </div>
                  </template>
                </v-checkbox>
              </v-row>
              <v-row no-gutters>
                <v-checkbox
                  class="mt-lg-n4"
                  v-model="emailNews"
                  color="darkblue"
                  :label="$t('label.label_email.email_aceita')"
                >
                </v-checkbox>
              </v-row>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-4 pb-4 mt-n6">
          <v-spacer></v-spacer>

          <v-btn
            rounded
            color="darkpink"
            class="white--text px-4 ml-2"
            @click="editPassword()"
            :disabled="!enabled"
          >
            {{ $t('botoes.botao_alterar') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="profileDialog"
      :max-width="widthDialog"
      :persistent="editMode"
    >
      <v-card class="rounded-lg pa-4">
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="12" class="text-center">
              <v-img
                style="border: 3px solid #1670e8"
                :src="
                  clientInfo.avatar === null
                    ? '../assets/DefaultAvatar.svg'
                    : clientInfo.avatar
                "
                width="120px"
                :aspect-ratio="1"
                class="d-flex align-center text-center mx-auto rounded-circle"
              >
                <v-btn
                  fab
                  :x-large="$vuetify.breakpoint.smAndUp"
                  class="white--text elevation-24"
                  color="#00000090"
                  @click="handleFileImport()"
                  v-if="editMode"
                >
                  <v-icon>mdi-camera</v-icon>
                </v-btn>
              </v-img>
            </v-col>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept="image/png, image/jpeg"
              @change="onFileChanged"
            />
            <v-col cols="12" class="mt-4">
              <div class="text-center text-h6">{{ clientInfo.name }}</div>
              <div class="text-center text-body-2">
                {{ clientInfo.username }}
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions class="mt-8 mt-sm-4">
          <v-row v-if="!editMode">
            <v-col cols="12" sm="6" class="text-center">
              <v-btn
                rounded
                color="darkpink"
                class="white--text"
                :width="$vuetify.breakpoint.xsOnly ? '200px' : '160px'"
                @click="editMode = true"
              >
                {{ $t('botoes.botao_meuperfil') }}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" class="text-center">
              <v-btn
                rounded
                color="darkpink"
                class="white--text mt-n4 mt-sm-0"
                :width="$vuetify.breakpoint.xsOnly ? '200px' : '160px'"
                @click="logutDialogOpen()"
              >
                {{ $t('botoes.botao_sair') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-form ref="editData">
            <v-row v-if="editMode" no-gutters>
              <v-col cols="12">
                <v-text-field
                  :label="$t('student.student_label.label7')"
                  v-model="clientInfo.email"
                  color="darkblue"
                  outlined
                  :rules="[rules.required, rules.email]"
                  append-icon="mdi-at"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  :label="$t('student.student_label.label6')"
                  v-model="clientInfo.username"
                  color="darkblue"
                  readonly
                  outlined
                  type="password"
                  @click="passDialog = true"
                  append-icon="mdi-lock-outline"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="$t('student.student_label.label5')"
                  v-model="clientInfo.phone"
                  color="darkblue"
                  append-icon="mdi-cellphone"
                  v-mask="'(##) #########'"
                  outlined
                  :rules="[rules.required, rules.phoneLength(13)]"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" class="d-flex justify-end">
                <v-btn rounded color="darkpink" text @click="editMode = false">
                  {{ $t('botoes.botao_cancelar') }}
                </v-btn>
                <v-btn
                  rounded
                  color="darkpink white--text ml-4"
                  @click="saveEdit()"
                >
                  {{ $t('botoes.botao_salva') }}
                  <v-icon v-if="$vuetify.breakpoint.smAndUp" small right
                    >mdi-check</v-icon
                  >
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="passDialog"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
      width="40%"
    >
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text">
          {{ $t('label.label_senha.senha_redefinir') }}
        </v-card-title>
        <v-card-text class="pt-6">
          <v-form ref="editPass">
            <v-row no-gutters>
              <v-col cols="12" class="px-2">
                <v-text-field
                  color="darkblue"
                  :label="$t('label.label_senha.label_senha')"
                  outlined
                  :rules="[rules.required, rules.length(4), rules.verify]"
                  :type="showPassword1 ? 'text' : 'password'"
                  :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword1 = !showPassword1"
                  :placeholder="$t('label.login_painel.senha2')"
                  v-model="currentPassword"
                />
              </v-col>
              <v-col cols="12" sm="6" class="px-2">
                <v-text-field
                  color="darkblue"
                  :label="$t('label.login_painel.senha2')"
                  outlined
                  :rules="checkPasswordRules.concat(passwordCheckRule)"
                  :type="showPassword2 ? 'text' : 'password'"
                  :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword2 = !showPassword2"
                  :placeholder="$t('label.login_painel.senha2')"
                  v-model="newPassword"
                />
              </v-col>
              <v-col cols="12" sm="6" class="px-2">
                <v-text-field
                  color="darkblue"
                  :label="$t('label.label_senha.senha_confirmar')"
                  outlined
                  :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
                  :type="showPassword3 ? 'text' : 'password'"
                  :append-icon="showPassword3 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword3 = !showPassword3"
                  :placeholder="$t('label.login_painel.senha2')"
                  v-model="confirmPassword"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-4 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            rounded
            color="darkpink"
            class="white--text px-4"
            text
            @click="passDialog = false"
          >
            {{ $t('botoes.botao_cancelar') }}
          </v-btn>
          <v-btn
            rounded
            :loading="loading"
            color="darkpink"
            class="white--text px-4 ml-2"
            @click="editPasswordConfirm()"
          >
            {{ $t('botoes.botao_salva') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="successSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="success"
      outlined
      text
    >
      <v-icon
        :large="$vuetify.breakpoint.smAndUp ? true : false"
        color="success"
        left
        >mdi-check-circle
      </v-icon>
      <span class="black--text text-subtitle-1">
        {{ $t('alerta.alerta_sucesso') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="successSnackbar = false"
          rounded
          color="success"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-main>
      <!-- Aqui -->
      <Manager v-if="page == $t('admin.admin_menu.menu12')" />
      <School v-if="page == $t('admin.admin_menu.menu9')" />
      <Teacher v-if="page == $t('admin.admin_menu.menu10')" />
      <Event v-if="page == $t('admin.admin_menu.menu7')" />
      <Student v-if="page == $t('admin.admin_menu.menu11')" />
      <Teams v-if="page == $t('admin.admin_menu.menu13')" />
      <Courses v-if="page == $t('admin.admin_menu.menu2')" />
      <Charts v-if="page == $t('admin.admin_menu.menu30')" />
    </v-main>
  </v-app>
</template>
<script>
import Manager from "../components/Client/ClientManager.vue";
import School from "../components/Client/ClientSchool.vue";
import Teacher from "../components/Client/ClientTeacher.vue";
import Event from "../components/Client/ClientEvent.vue";
import Student from "../components/Client/ClientStudent.vue";
import Teams from "../components/Client/ClientTeams.vue";
import Courses from "../components/Client/ClientCourses.vue";
import Charts from "../components/Client/ClientChats.vue";

const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");

var checking = true;

export default {
  name: "Client",
  data: () => ({
    passDialog: false,
    loading: false,
    enabled: false,
    emailNews: false,
    firstAccess: false,
    profileDialog: false,
    showPassword1: false,
    showPassword2: false,
    showPassword3: false,
    editMode: false,
    successSnackbar: false,
    isSelecting: false,
    phone: null,
    rules: {
      email: (v) =>
        !!(v || "").match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) || this.$t('regras.regra_email'),
      phoneLength: (len) => (v) =>
        (v || "").length >= len ||
        `${this.$t('regras.regra_telefone.telefone1')} ${len - 5} ${this.$t('regras.regra_telefone.telefone2')}`,
      length: (len) => (v) =>
        (v || "").length >= len ||
        `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')} `,
      maxLength: (len) => (v) =>
        (v || "").length <= len ||
        `${this.$t('regras.regra_senhaMax.senha1')} ${len} ${this.$t('regras.regra_senhaMax.senha2')}`,
      required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
      option: (v) => !!v || this.$t('regras.regra_opcao'),
      image: (v) => !!v || this.$t('regras.regra_image'),
      question: (v) => !!v || this.$t('regras.regra_question'),
      date: (v) => !!v || this.$t('regras.regra_data'),
      user: (v) =>
        !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
        `${this.$t('regras.regra_user')}`,
      verify: () => checking || this.$t('regras.regra_senhaincorreta'),
      none: true,
    },
    confirmPasswordRules: [(v) => !!v || this.$t('regras.regra_obrigatorio')],
    checkPasswordRules: [(v) => !!v || this.$t('regras.regra_obrigatorio')],
    newPassword: "",
    confirmPassword: "",
    currentPassword: "",
    drawer: null,
    logoutDialog: false,
    menu: [],
    page: "",
    clientInfo: {},
  }),
  components: {
    Manager,
    School,
    Event,
    Teacher,
    Student,
    Teams,
    Courses,
    Charts,
  },
  beforeRouteLeave(to, from, next) {
    if (confirm(this.$t('alerta.alerta_sair.sair3'))) {
      next(); // Permite a mudança de rota
    } else {
      next(false); // Cancela a mudança de rota
    }
  },  
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.newPassword === this.confirmPassword || this.$t('login.login_painel.coincidem');
    },
    passwordCheckRule() {
      return () =>
        this.newPassword !== this.currentPassword ||
        this.$t('login.login_painel.senhaigual');
    },
    widthDialog() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "90%";
        case "sm":
          return "90%";
        case "md":
          return "50%";
        case "lg":
          return "30%";
        case "xl":
          return "30%";
        default:
          return "30%";
      }
    },
  },
  mounted() {
    document.title = this.$t('admin.admin_menu.menu8');
    this.menu = [
      {
        title: this.$t('admin.admin_menu.menu9'),
        icon: "mdi-school",
      },
      {
        title: this.$t('admin.admin_menu.menu10'),
        icon: "mdi-human-male-board",
      },
      {
        title: this.$t('admin.admin_menu.menu13'),
        icon: "mdi-account-group",
      },
      {
        title: this.$t('admin.admin_menu.menu11'),
        icon: "mdi-account-school",
      },
      {
        title: this.$t('admin.admin_menu.menu12'),
        icon: "mdi-human-male-board",
      },
      {
        title: this.$t('admin.admin_menu.menu7'),
        icon: "mdi-calendar-star",
      },
      {
        title: this.$t('admin.admin_menu.menu2'),
        icon: "mdi-folder-open",
      },
      {
        title: this.$t('admin.admin_menu.menu30'),
        icon: "mdi-finance",
      },
    ],
    this.page = this.$t('admin.admin_menu.menu9')
  },
  created() {
    this.getClient();
  },
  methods: {
    handleFileImport() {
      this.isSelecting = true;

      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    async editPasswordConfirm() {
      let validation = this.$refs.editPass.validate();

      if (validation) {
        let data = {
          newPassword: this.confirmPassword,
          currentPassword: this.currentPassword,
        };

        let res = await axios.post(
          `${url}/clients/alterConfirmedPassword`,
          data,
          {
            headers: {
              authorization: token,
            },
          }
        );

        let verify = res.data;

        if (verify.status == "success") {
          this.$refs.editPass.reset();
          this.passDialog = false;
          this.successSnackbar = true;
          checking = true;
        } else {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
            checking = false;
            this.$refs.editPass.validate();
            setTimeout(() => {
              checking = true;
              this.$refs.editPass.validate();
            }, 2500);
          }, 1000);
        }
      }
    },
    async saveEdit() {
      let validation = this.$refs.editData.validate();

      if (validation) {
        try {
          let data = {
            email: this.clientInfo.email,
            phone: this.clientInfo.phone,
          };
          let id = this.clientInfo.id;
          await axios.put(`${url}/clients/${id}`, data, {
            headers: {
              authorization: token,
            },
          });
          this.successSnackbar = true;
          this.editMode = false;
          this.passDialog = false;
          this.profileDialog = false;
        } catch (error) {
          console.error(error);
        }
      }
    },
    async onFileChanged(e) {
      let selected = e.target.files[0];
      let formData = new FormData();
      formData.append("file", selected, selected.name);
      let res = await axios.post(`${url}/lessonsExtras/files`, formData);
      let uri = res.data.url;
      let data = { avatar: uri };
      let id = this.clientInfo.id;
      await axios.put(`${url}/clients/${id}`, data, {
        headers: {
          authorization: token,
        },
      });
      setTimeout(() => {
        this.clientInfo.avatar = uri;
      }, 500);
    },
    logutDialogOpen() {
      this.profileDialog = false;
      this.logoutDialog = true;
    },
    async getClient() {
      let res = await axios.get(`${url}/clients/findOne`, {
        headers: {
          authorization: token,
        },
      });

      this.clientInfo = res.data;

      if (
        this.clientInfo.acceptedTerms == null ||
        this.clientInfo.acceptedTerms == 0
      ) {
        this.firstAccess = true;
      }
    },
    async editPassword() {
      let validation = this.$refs.editPass.validate();

      if (validation) {
        try {
          let data = {
            phone: this.phone,
            password: this.confirmPassword,
            acceptedTerms: this.enabled,
          };

          await axios.post(`${url}/clients/updatePassword`, data, {
            headers: {
              authorization: token,
            },
          });

          this.getClient();

          this.firstAccess = false;
        } catch (error) {
          console.error(error);
        }
      }
    },
    setMessage(msg) {
      this.page = msg;
    },
    toLogin() {
      this.$router.go("/login/Pedagogico");
    },
    logout() {
      this.logoutDialog = false;
      this.toLogin();
      localStorage.removeItem("accessToken");
    },
  },
};
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f128;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(121, 121, 121);
}

.logoutStyle {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
</style>
