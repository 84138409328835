<template>
  <div>
    <v-toolbar dense class="elevation-1">
      <v-icon color="darkblue" class="text-h4">mdi-account-group</v-icon>
      <v-toolbar-title
        :style="
          $vuetify.breakpoint.smAndUp
            ? 'letter-spacing: 0.5rem'
            : 'letter-spacing: 0.2rem'
        "
        class="ml-3 font-weight-bold"
      >
        {{ $t('school.school_titleturma') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <v-card class="rounded-lg">
          <v-card-title class="darkblue white--text">
            <span class="text-h5">{{ $t('school.school_novaturma') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="teamsForm">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      color="blue"
                      v-model="formTeam.name"
                      :rules="[rules.required]"
                      :label="$t('label.label_nometurma')"
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      color="blue"
                      item-color="darkblue"
                      v-model="formTeam.courseId"
                      :items="courses"
                      :rules="[rules.option]"
                      :label="$t('label.label_curso')"
                      :no-data-text="$t('admin.admin_selecione.selecione1')"
                      item-value="courseId"
                      item-text="Course.name"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      color="blue"
                      item-color="darkblue"
                      v-model="formTeam.teacherId"
                      :items="teachers"
                      :no-data-text="$t('admin.admin_selecione.selecione1')"
                      :label="$t('label.label_professor')"
                      :rules="[rules.option]"
                      item-value="id"
                      item-text="name"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>*{{ $t('regras.regra_obrigatorio') }}</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darkpink" rounded text @click="closeRegister()">
              {{ $t('botoes.botao_cancelar') }}
            </v-btn>
            <v-btn color="darkpink" rounded text @click="saveBtn()">
              {{ $t('botoes.botao_salva') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card
        class="rounded-lg py-3 error--text"
        style="border-left: 12px inset red"
      >
        <v-card-title>
          <v-icon left large color="error">mdi-alert-circle</v-icon>
          {{ $t('alerta.alerta_title') }}
        </v-card-title>
        <v-card-text class="black--text">
          {{ $t('alerta.alerta_excluir.excluir1') }}
        </v-card-text>
        <v-card-actions class="d-flex justify-end pr-4">
          <v-btn
            color="error"
            dark
            rounded
            class="px-3"
            @click="deleteItemConfirm()"
          >
            {{ $t('botoes.botao_excluir') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="ma-4">
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        v-for="team in teams"
        :key="team.id"
      >
        <v-card
          class="rounded-xl d-flex flex-column justify-space-between pointer"
          @click="getDetails(team)"
          elevation="4"
          height="300"
        >
          <v-img
            :src="team.thumbnail"
            class="rounded-t-xl"
            height="200"
          ></v-img>

          <v-card-actions class="darkblue pa-4 white--text">
            <v-row>
              <v-col cols="12">
                <div
                  class="font-weight-bold"
                  :style="
                    $vuetify.breakpoint.xsOnly
                      ? 'font-size: 1rem'
                      : 'font-size: 1.1rem'
                  "
                >
                  {{ team.name }}
                </div>
                <div
                  class="font-weight-regular"
                  :style="
                    $vuetify.breakpoint.xsOnly
                      ? 'font-size: 0.8rem'
                      : 'font-size: 0.9rem'
                  "
                >
                  {{ team.school }}
                </div>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="teamDialog"
      :width="$vuetify.breakpoint.mdAndUp ? '60%' : '90%'"
    >
      <v-card class="rounded-lg">
        <v-tabs v-model="classTab" background-color="darkblue" grow dark>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#1"> {{ $t('botoes.botao_detalhe') }} </v-tab>
          <v-tab href="#2"> {{ $t('admin.admin_menu.menu3') }} </v-tab>
          <v-tab href="#3"> {{ $t('admin.admin_menu.menu11') }} </v-tab>
        </v-tabs>
        <v-tabs-items :value="classTab">
          <v-tab-item value="1">
            <v-card-actions class="mt-2 d-flex justify-end">
              <v-btn
                rounded
                outlined
                small
                @click="editItem(teamDetails.all)"
                color="darkblue"
                class="px-2 mx-1"
              >
                <v-icon small class="mr-1">mdi-pencil</v-icon>
                {{ $t('botoes.botao_edita') }}
              </v-btn>
              <v-btn
                rounded
                outlined
                small
                @click="deleteItem(teamDetails.all)"
                color="error"
                class="px-2 mx-1"
              >
                <v-icon small class="mr-1">mdi-delete</v-icon>
                {{ $t('botoes.botao_excluir') }}
              </v-btn>
            </v-card-actions>
            <v-card-text
              class="px-4 pb-6 pb-sm-8 pb-md-10 pb-lg-16 d-flex flex-row"
            >
              <div class="text-center">
                <v-avatar
                  :size="$vuetify.breakpoint.mdAndUp ? 260 : 200"
                  tile
                  class="rounded-xl mx-auto"
                >
                  <v-img :src="teamDetails.thumb" />
                </v-avatar>
              </div>

              <div style="font-size: 1.2rem" class="mt-4 ml-8">
                <div style="font-size: 1.6rem" class="font-weight-black">
                  {{ teamDetails.team }}
                </div>

                <div class="mt-4">
                  <strong>{{ $t('student.student_label.label3') }} </strong> {{ teamDetails.school }}
                </div>
                <div>
                  <strong>{{ $t('teams.team_detalhes.detalhes1') }}</strong> {{ teamDetails.course }}
                </div>
                <div>
                  <strong>{{ $t('teams.team_detalhes.detalhes2') }}</strong> {{ teamDetails.teacher }}
                </div>
              </div>
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="2">
            <v-card-text class="my-4">
              <v-alert
                :color="colorLookLight(index)"
                text
                dense
                outlined
                v-for="(data, index) in classes"
                :key="data.id"
                :icon="$vuetify.breakpoint.xsOnly ? false : 'mdi-bookshelf'"
                :prominent="!$vuetify.breakpoint.xsOnly"
                class="my-3"
              >
                <v-row align="center" no-gutters>
                  <v-col
                    cols="12"
                    sm="8"
                    lg="9"
                    class="grow font-weight-bold black--text"
                  >
                    <div style="font-size: 1.1rem">
                      {{ data.title }}
                    </div>
                    <div style="font-size: 0.9rem">
                      {{ data.project }}
                    </div>
                  </v-col>

                  <v-col cols="12" sm="4" lg="3" class="pa-0 ma-0 black--text">
                    <div>
                      <v-icon v-if="!data.applied" left color="red">
                        mdi-close
                      </v-icon>
                      <v-icon v-if="data.applied" left color="green">
                        mdi-check
                      </v-icon>
                      <span
                        v-text="data.applied ? $t('label.label_aplicado.ativo') : $t('label.label_aplicado.desativo')"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-alert>
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="3">
            <v-card-text v-if="students.length !== 0" class="my-4">
              <v-alert
                :color="colorLookLight(index)"
                text
                outlined
                v-for="(student, index) in students"
                :key="index"
                :icon="$vuetify.breakpoint.xsOnly ? false : 'mdi-school'"
                :prominent="!$vuetify.breakpoint.xsOnly"
                class="my-3"
              >
                <v-row align="center" no-gutters>
                  <v-col
                    cols="12"
                    sm="8"
                    lg="9"
                    class="grow font-weight-bold black--text"
                  >
                    <div style="font-size: 1.2rem">
                      {{ student.name }}
                    </div>
                    <div style="font-size: 0.9rem" class="d-flex flex-column">
                      <span>{{ student.user }}</span>
                      <span>{{ student.email }}</span>
                      <span>{{ student.phone }}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-alert>
            </v-card-text>
            <v-card-text v-if="students.length === 0" class="pa-4 pb-8">
              <v-img
                src="../../assets/MoonBalloon.svg"
                max-width="320px"
                class="mx-auto"
              />
              <div
                class="text-center text-h6 text-sm-h5 font-weight-bold mt-2"
                style="color: #491670"
              >
                {{ $t('teams.team_semaluno') }}
              </div>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="successSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="success"
      outlined
      text
    >
      <v-icon large color="success" class="mr-2">mdi-check-circle </v-icon>
      <span class="subtitle-1 black--text">
        {{ $t('alerta.alerta_sucesso') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="successSnackbar = false"
          rounded
          color="success"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="error"
      outlined
      text
    >
      <v-icon large color="error" class="mr-2">mdi-close-circle </v-icon>
      <span class="subtitle-1 black--text">
        {{ $t('alerta.alerta_erro') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="errorSnackbar = null"
          rounded
          color="error"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped></style>

<script>
const url = process.env.VUE_APP_API_URL;
const axios = require("axios");
const token = "Baerer " + localStorage.getItem("accessToken");
export default {
  data() {
    return {
      menu: [
        {
          title: this.$t('teams.team_atalho.atalho2'),
        },
      ],
      dialog: null,
      formTeam: {},
      search: "",
      headers: [
        { text: this.$t('school.school_headers.heardes5'), value: "team" },
        { text: this.$t('regisResponsavel.painel.escola'), value: "school" },
        { text: this.$t('school.school_curso'), value: "course" },
        { text: this.$t('login.login_usuario.usuario3'), value: "teacher" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      teams: [],
      editedIndex: -1,
      dialogDelete: null,
      errorSnackbar: false,
      successSnackbar: false,
      editMode: false,
      rules: {
        email: (v) =>
          !!(v || "").match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        maxLength: (len) => (v) =>
          (v || "").length <= len ||
          `${this.$t('regras.regra_senhaMax.senha1')} ${len} ${this.$t('regras.regra_senhaMax.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        user: (v) =>
          !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
          `${this.$t('regras.regra_user')}`,
        none: true,
      },
      schools: [],
      courses: [],
      teachers: [],
      addStudent: false,
      teamSingular: "",
      teamDialog: false,
      classTab: 1,
      classes: [],
      students: [],
      teamDetails: {
        id: "",
        thumb: "",
        team: "",
        course: "",
        teacher: "",
        school: "",
      },
      classColors: [
        { light: "pink ", dark: "darkpink" },
        { light: "cyan", dark: "darkcyan" },
        { light: "yellow", dark: "darkyellow" },
      ],
    };
  },
  created() {
    this.getTeam();
    this.getSchools();
    this.getCoursesTeachers();
  },
  methods: {
    colorLookLight(index) {
      let position = (index + 1) % 3;
      let color = this.classColors[position].light;
      return color;
    },
    colorLookDark(index) {
      let position = (index + 1) % 3;
      let color = this.classColors[position].dark;
      return color;
    },
    getTeamStudent(team) {
      this.teamSingular = team.name;

      localStorage.setItem("teamSingular", team.name);
      this.$emit("message", "Alunos");
      this.$parent.$parent.$parent.page = "Alunos";
    },
    addOpen(item) {
      this.getStudentBySchoolGrade(item);
      this.addStudent = true;
    },
    getStudentBySchoolGrade(team) {
      axios
        .get(
          `${url}/studentsBySchoolGrade/?schoolId=${team.schoolId}&grade=${team.grade}`,
          {
            headers: {
              authorization: token,
            },
          }
        )
        .then((res) => {
          this.students = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getCoursesTeachers() {
      axios
        .get(`${url}/courseInscriptionsForSchool`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.courses = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
      axios
        .get(`${url}/teachersForSchool`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.teachers = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getSchools() {
      axios
        .get(`${url}/schools`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.schools = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getTeam() {
      axios
        .get(`${url}/teamsForSchoolClient`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.teams = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async saveBtn() {
      let validation = this.$refs.teamsForm.validate();

      if (validation) {
        if (!this.editMode) {
          try {
            await this.registerTeam();
            this.editMode = false;
            setTimeout(() => {
              this.successSnackbar = true;
            }, 1000);
          } catch {
            this.errorSnackbar = true;
          }
        }
        if (this.editMode) {
          try {
            await this.updateTeam(this.formTeam.id);
            this.editMode = false;
            setTimeout(() => {
              this.successSnackbar = true;
            }, 1000);
          } catch {
            this.errorSnackbar = true;
          }
        }
        this.$refs.teamsForm.reset();
        this.dialog = false;
      }
    },
    // registerTeam() {
    //   axios
    //     .post(`${url}/TeamsForSchool`, this.formTeam, {
    //       headers: {
    //         authorization: token,
    //       },
    //     })
    //     .then((res) => {

    //       this.getTeam();
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // },
    updateTeam(id) {
      axios
        .put(`${url}/teams/${id}`, this.formTeam, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.closeRegister();
          this.getTeam();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getDetails(data) {
      this.teamDetails.id = data.id;
      this.teamDetails.thumb = data.thumbnail;
      this.teamDetails.team = data.name;
      this.teamDetails.course = data.course;
      this.teamDetails.teacher = data.teacher;
      this.teamDetails.school = data.school;
      this.teamDetails.all = data;

      const body = {
        teacherId: data.teacherId,
        teamId: data.id,
        schoolId: data.schoolId,
        courseId: data.courseId,
      };

      this.getClassDetails(body);
      this.getStudents(data.id);

      this.classTab = 1;
      this.teamDialog = true;
    },
    getClassDetails(body) {
      axios
        .post(`${url}/appliedClasses/applied`, body, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.classes = res.data;

          for (let index = 0; index < this.classes.length; index++) {
            const element = this.classes[index];
            if (!element.applied) this.applyStatus = false;

            if (!element.visible) this.visibleStatus = false;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getStudents(id) {
      let res = await axios.get(`${url}/student/team/${id}`);

      this.students = res.data.result;
    },
    deleteItemConfirm() {
      axios
        .delete(`${url}/teams/${this.editedIndex}`, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.closeDelete();
          this.getTeam();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    closeRegister() {
      this.dialog = false;
      this.editMode = false;
      this.$refs.teamsForm.reset();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.formTeam = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = item.id;

      this.dialogDelete = true;
    },
    editItem(item) {
      this.editedIndex = item.id;
      this.formTeam = Object.assign({}, item);
      this.getCoursesTeachers();
      this.dialog = true;
      this.editMode = true;
    },
  },
};
</script>
