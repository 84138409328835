<template>
  <div class="pb-8">
    <v-toolbar dense class="elevation-1">
      <v-icon color="yellow" class="text-h4">mdi-human-male-board</v-icon>
      <v-toolbar-title
        :style="
          $vuetify.breakpoint.smAndUp
            ? 'letter-spacing: 0.5rem'
            : 'letter-spacing: 0.2rem'
        "
        class="ml-3 font-weight-bold"
      >
        {{ $t('school.school_titleprofessor') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <!-- <template v-slot:activator="{ on, attrs }">
          <v-btn color="darkpink" dark rounded v-bind="attrs" v-on="on">
            <v-icon left> mdi-plus-thick </v-icon>
            Novo
          </v-btn>
        </template> -->
        <v-card class="rounded-lg" elevation="5">
          <v-card-title class="darkblue white--text mb-4">
            <span class="text-h5">{{ $t('school.school_novoprofessor') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="tForm">
                <v-row>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-text-field
                      color="darkblue"
                      v-model="formTeacher.name"
                      :rules="[rules.required]"
                      :label="$t('label.label_nome')"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-text-field
                      color="darkblue"
                      v-model="formTeacher.user"
                      :rules="[rules.required]"
                      :label="$t('label.label_usuario')"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      color="darkblue"
                      :label="$t('label.label_email.email')"
                      :rules="[rules.required, rules.email]"
                      v-model="formTeacher.email"
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12" v-if="!editMode">
                    <v-text-field
                      color="darkblue"
                      v-model="formTeacher.password"
                      :rules="[rules.required, rules.length(4)]"
                      :label="$t('label.label_senha.label_senhaob')"
                      :type="showPassword ? 'text' : 'password'"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12">
                    <v-select
                      color="darkblue"
                      item-color="darkblue"
                      v-model="formTeacher.schoolId"
                      :items="schools"
                      :label="$t('regisResponsavel.painel.escola')"
                      :no-data-text="$t('regisResponsavel.resgistrocadastro')"
                      item-text="name"
                      item-value="id"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>*{{ $t('regras.regra_obrigatorio') }}</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darkpink" rounded text @click="closeRegister()">
              {{ $t('botoes.botao_cancelar') }}
            </v-btn>
            <v-btn color="darkpink" rounded text @click="saveBtn()">
              {{ $t('botoes.botao_salva') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card
        class="rounded-lg py-3 error--text"
        style="border-left: 12px inset red"
      >
        <v-card-title>
          <v-icon left large color="error">mdi-alert-circle</v-icon>
          {{ $t('alerta.alerta_title') }}
        </v-card-title>
        <v-card-text class="black--text">
          {{ $t('alerta.alerta_excluir.excluir1') }}
        </v-card-text>
        <v-card-actions class="d-flex justify-end pr-4">
          <v-btn
            color="error"
            dark
            rounded
            class="px-3"
            @click="deleteItemConfirm()"
          >
            {{ $t('botoes.botao_excluir') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="ma-4">
      <v-col
        cols="12"
        md="6"
        lg="4"
        v-for="teacher in teachers"
        :key="teacher.id"
      >
        <v-card
          class="rounded-lg py-4 px-2"
          style="height: 100%"
          elevation="2"
          outlined
        >
          <div
            class="
              d-flex
              flex-column
              align-center
              justify-space-around
              text-center
            "
            style="height: 100%; min-height: 25vh"
          >
            <div v-if="teacher.avatar !== null">
              <v-img
                width="90"
                height="90"
                class="rounded-circle"
                :src="teacher.avatar"
              >
              </v-img>
            </div>
            <div>
              <div class="font-weight-bold" style="font-size: 1.2rem">
                {{ teacher.name }}
              </div>
              <div class="mb-4 font-weight-medium" style="font-size: 0.9rem">
                {{ teacher.user }}
              </div>
            </div>
            <div style="font-size: 0.9rem">
              <div>
                <span class="font-weight-bold"> {{ $t('student.student_label.label3') }} </span>
                {{ teacher.school }}
              </div>
              <div>
                <span class="font-weight-bold"> {{ $t('student.student_label.label7') }} </span>
                {{ teacher.email }}
              </div>
              <div>
                <span class="font-weight-bold"> {{ $t('student.student_minhainformacao.informacao5') }} </span>
                {{ teacher.phone }}
              </div>
            </div>
            <div class="mt-6">
              <v-btn
                rounded
                outlined
                small
                icon
                @click="editItem(teacher)"
                color="darkblue"
                class="px-2 mx-1"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                rounded
                outlined
                small
                icon
                @click="deleteItem(teacher)"
                color="error"
                class="px-2 mx-1"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="successSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="success"
      outlined
      text
    >
      <v-icon large color="success" class="mr-2">mdi-check-circle </v-icon>
      <span class="subtitle-1 black--text">
        {{ $t('alerta.alerta_sucesso') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="successSnackbar = false"
          rounded
          color="success"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="error"
      outlined
      text
    >
      <v-icon large color="error" class="mr-2">mdi-close-circle </v-icon>
      <span class="subtitle-1 black--text">
        {{ $t('alerta.alerta_erro') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="errorSnackbar = null"
          rounded
          color="error"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped></style>

<script>
const url = process.env.VUE_APP_API_URL;
const axios = require("axios");
const token = "Baerer " + localStorage.getItem("accessToken");
export default {
  data() {
    return {
      dialog: null,
      showPassword: false,
      formTeacher: {},
      search: "",
      headers: [
        { text: this.$t('certificado.cert_headers.headers1'), value: "name" },
        { text: this.$t('label.label_email.email'), value: "email" },
        { text: this.$t('regisResponsavel.painel.escola'), value: "school" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      teachers: [],
      editedIndex: -1,
      dialogDelete: null,
      successSnackbar: false,
      errorSnackbar: false,
      editMode: true,
      rules: {
        email: (v) =>
          !!(v || "").match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
           `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        maxLength: (len) => (v) =>
          (v || "").length <= len ||
          `${this.$t('regras.regra_senhaMax.senha1')} ${len} ${this.$t('regras.regra_senhaMax.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        user: (v) =>
          !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
          `${this.$t('regras.regra_user')}`,
        none: true,
      },
      schools: [],
      selectedSchool: [],
      varias: null,
    };
  },

  created() {
    this.getTeacher();
    this.getSchools();
  },
  methods: {
    getSchools() {
      axios
        .get(`${url}/schools`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.schools = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getTeacher() {
      axios
        .get(`${url}/teachersForClient`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.teachers = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    registerTeacher() {
      axios
        .post(`${url}/teachers`, this.formTeacher, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {})
        .catch((error) => {
          console.error(error);
        });
    },
    updateTeacher(id) {
      axios
        .put(`${url}/teachers/${id}`, this.formTeacher, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.getTeacher();
        })
        .catch((error) => {
          console.error(error);
          alert("Error");
        });
    },
    async saveBtn() {
      let validation = this.$refs.tForm.validate();

      if (validation) {
        if (!this.editMode) {
          try {
            await this.registerTeacher();
            this.editMode = false;
            setTimeout(() => {
              this.successSnackbar = true;
            }, 1000);
          } catch {
            this.errorSnackbar = true;
          }
        }
        if (this.editMode) {
          try {
            await this.updateTeacher(this.formTeacher.id);

            this.editMode = false;
            setTimeout(() => {
              this.successSnackbar = true;
            }, 1000);
          } catch {
            this.errorSnackbar = true;
          }
        }
        this.$refs.tForm.reset();
        this.dialog = false;
      }
    },
    deleteItemConfirm() {
      axios
        .delete(`${url}/teachersByEmail`, {
          headers: {
            authorization: token,
          },
          data: { email: this.editedIndex },
        })
        .then(() => {
          this.closeDelete();
          this.getTeacher();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    closeRegister() {
      this.dialog = false;
      this.editMode = false;
      this.$refs.tForm.reset();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.formTeacher = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = item.email;
      this.formTeacher = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async editItem(item) {
      let res = await axios.get(
        `${url}/teacherInscriptions/?teacher=${item.id}`,
        {
          headers: {
            authorization: token,
          },
        }
      );
      res.data.forEach((element) => {
        this.selectedSchool.push(element.school.id);
      });
      this.editedIndex = item.id;
      this.formTeacher = Object.assign({}, item);
      this.dialog = true;
      this.editMode = true;
    },
  },
};
</script>
