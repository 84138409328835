<template>
  <div class="backcolor pb-2" style="min-height: 100vh">
    <v-tabs
      v-model="tab"
      background-color="darkcolor"
      centered
      dark
      dense
      :fixed-tabs="$vuetify.breakpoint.xsOnly"
      :icons-and-text="$vuetify.breakpoint.smAndUp"
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1">
        <span v-if="$vuetify.breakpoint.smAndUp"> {{ $t('admin.admin_menu.menu14') }} </span>
        <v-icon>mdi-finance</v-icon>
      </v-tab>

      <v-tab href="#tab-2">
        <span v-if="$vuetify.breakpoint.smAndUp"> {{ $t('gestor.gestor_alunoativo') }} </span>
        <v-icon>mdi-account</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="ma-4 backcolor">
      <v-tab-item value="tab-1">
        <v-row>
          <v-col cols="12" sm="6" md="3" v-for="(t, i) in totalCards" :key="i">
            <v-card class="rounded-lg" flat style="height: 100%">
              <div
                class="d-flex align-center justify-center"
                style="height: 100%"
              >
                <div style="width: 30%" class="text-center">
                  <v-icon
                    size="40"
                    class="pa-3 rounded-circle"
                    :class="t.back"
                    :color="t.color"
                    >{{ t.icon }}</v-icon
                  >
                </div>
                <div style="width: 70%" class="d-flex flex-column py-6">
                  <span style="font-size: 1.2rem" class="font-weight-medium">
                    {{ t.name }}
                  </span>
                  <span style="font-size: 1.6rem" class="font-weight-bold">
                    {{ t.value }}
                  </span>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card class="rounded-lg" flat style="height: 100%">
              <v-card-title>
                {{ $t('admin.admin_acessousuario') }}&nbsp; <b>{{ currentMonth }}</b>
              </v-card-title>
              <v-card-text>
                <ChartAccess :chartData="chartData" ref="userChart" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <v-row>
          <v-col cols="12" md="4" v-for="(a, i) in activeCards" :key="i">
            <v-card class="rounded-lg" flat style="height: 100%">
              <div
                class="d-flex align-center justify-center"
                style="height: 100%"
              >
                <div style="width: 30%" class="text-center">
                  <v-icon
                    size="40"
                    class="pa-3 rounded-circle"
                    :class="a.back"
                    :color="a.color"
                    >{{ a.icon }}</v-icon
                  >
                </div>
                <div style="width: 70%" class="d-flex flex-column py-6">
                  <span style="font-size: 1.2rem" class="font-weight-medium">
                    {{ a.name }}
                    <v-tooltip bottom v-if="i === 2">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                      </template>
                      <div style="max-width: 385px">
                        {{ $t('admin.admin_contagemtotal') }}
                      </div>
                    </v-tooltip>
                  </span>
                  <span style="font-size: 1.6rem" class="font-weight-bold">
                    {{ a.value }}
                  </span>
                  <span
                    v-if="i === 0 || i === 1"
                    @click="activeDialog = true"
                    style="
                      font-size: 0.75rem;
                      text-decoration: underline;
                      cursor: pointer;
                    "
                    class="mt-2 font-weight-medium"
                  >
                    {{ $t('admin.admin_verdetalhe') }}
                    <v-icon x-small color="black">mdi-arrow-right</v-icon>
                  </span>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="rounded-lg" flat>
              <v-card-title
                class="
                  font-weight-bold
                  d-lfex
                  justify-space-between
                  align-center
                "
              >
                <span>
                  {{ $t('admin.admin_acessorecente') }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon class="ml-2" v-on="on"
                        >mdi-help-circle-outline</v-icon
                      >
                    </template>
                    <div style="max-width: 385px">
                      {{ $t('admin.admin_tabelaloginrecente') }}
                    </div>
                  </v-tooltip>
                </span>
                <span
                  style="
                    font-size: 0.75rem;
                    text-decoration: underline;
                    cursor: pointer;
                  "
                  class="mt-2 font-weight-medium"
                  @click="allLogins = true"
                >
                  {{ $t('admin.admin_vertodos') }}
                  <v-icon x-small color="black">mdi-arrow-right</v-icon>
                </span>
              </v-card-title>
              <v-card-text>
                <v-data-table
                  dense
                  :headers="recentHeader"
                  :items="recentLoginsData"
                  @page-count="pageCount = $event"
                  :items-per-page="itemsPerPage"
                  :page.sync="page"
                  :footer-props="{
                    'items-per-page-options': [15, 30, 45, -1],
                    'items-per-page-all-text': $t('events.events_headers.headers1'),
                    'items-per-page-text': $t('student.student_paginas'),
                    'show-first-last-page': true,
                  }"
                >
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <v-menu
      v-if="tab === 'tab-1'"
      ref="menu"
      v-model="dataPicker"
      :close-on-content-click="false"
      :return-value.sync="dateForFilter"
      transition="scroll-x-reverse-transition"
      offset-y
      left
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          fixed
          right
          fab
          absolute
          large
          color="darkcolor"
          style="bottom: 40px; right: 40px"
          class="elevation-12"
        >
          <v-icon color="white">mdi-filter</v-icon>
        </v-btn>
      </template>
      <v-date-picker
        v-model="dateForFilter"
        @change="selectDate(dateForFilter)"
        type="month"
        no-title
        color="blue"
        :max="maxMonth"
        header-color="darkblue"
      >
      </v-date-picker>
    </v-menu>
    <v-dialog
      v-model="activeDialog"
      max-width="70%"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card class="rounded-lg">
        <v-card-title
          class="darkblue white--text d-flex justify-space-between align-center"
        >
          <span>{{ $t('gestor.gestor_alunoativo') }}</span>
          <v-icon @click="activeDialog = false" color="white">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="py-4">
          <v-data-table
            :headers="activeHeader"
            :items="activeStudents"
            @page-count="pageCount = $event"
            :items-per-page="itemsPerPage"
            :page.sync="page"
            :footer-props="{
              'items-per-page-options': [15, 30, 45, -1],
              'items-per-page-all-text': $t('events.events_headers.headers1'),
              'items-per-page-text': $t('student.student_paginas'),
              'show-first-last-page': true,
            }"
          >
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="allLogins"
      max-width="70%"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card class="rounded-lg">
        <v-card-title
          class="darkblue white--text d-flex justify-space-between align-center"
        >
          <span>{{ $t('admin.admin_todosacessos') }}</span>
          <v-icon @click="allLogins = false" color="white">mdi-close</v-icon>
        </v-card-title>
        <div style="height: 75vh; overflow-y: scroll">
          <v-card-text class="py-4">
            <v-data-table
              :headers="recentHeader"
              :items="allRecentLoginsData"
              @page-count="pageCount2 = $event"
              :page.sync="page2"
              :items-per-page="itemsPerPage2"
              :footer-props="{
                'items-per-page-options': [15, 30, 45, -1],
                'items-per-page-all-text': $t('events.events_headers.headers1'),
                'items-per-page-text': $t('student.student_paginas'),
                'show-first-last-page': true,
              }"
            >
            </v-data-table>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");
const authorization = { headers: { authorization: token } };

import ChartAccess from "./Charts/ChartAccess.vue";

export default {
  components: {
    ChartAccess,
  },
  data() {
    return {
      page: 1,
      pageCount: null,
      itemsPerPage: 15,
      page2: 1,
      pageCount2: null,
      itemsPerPage2: 15,
      maxMonth: new Date().toISOString().substr(0, 7),
      dateForFilter: "",
      dataPicker: false,
      tab: null,
      formatedDate: "",
      currentMonth: "",
      chartData: { name: [], data: [[], [], []] },
      totalCards: [
        {
          icon: "mdi-account-group",
          name: this.$t('school.school_acesso'),
          value: 0,
          color: "pink accent-2",
          back: "pink-bg",
        },
        {
          icon: "mdi-school",
          name: this.$t('login.login_usuario.usuario4'),
          value: 0,
          color: "light-blue accent-4",
          back: "blue-bg",
        },
        {
          icon: "mdi-human-male-board",
          name: this.$t('admin.admin_menu.menu10'),
          value: 0,
          color: "amber accent-4",
          back: "yellow-bg",
        },
        {
          icon: "mdi-account-school",
          name: this.$t('teams.team_atalho.atalho2'),
          value: 0,
          color: "teal accent-3",
          back: "cyan-bg",
        },
      ],
      activeCards: [
        {
          icon: "mdi-account-group",
          name: this.$t('admin.admin_matriculaaluno'),
          value: 0,
          color: "pink accent-2",
          back: "pink-bg",
        },
        {
          icon: "mdi-account-school",
          name: this.$t('gestor.gestor_alunoativo'),
          value: 0,
          color: "teal accent-3",
          back: "cyan-bg",
        },
        {
          icon: "mdi-calendar",
          name: this.$t('admin.admin_acessodiario'),
          value: 0,
          color: "amber accent-4",
          back: "yellow-bg",
        },
      ],
      activeDialog: false,
      activeHeader: [
        { text: this.$t('regisResponsavel.painel.escola'), value: "school" },
        {
          text: this.$t('admin.admin_matriculaaluno'),
          value: "total_students",
          align: "center",
        },
        {
          text: this.$t('gestor.gestor_alunoativo'),
          value: "total_students_accepted_terms",
          align: "center",
        },
      ],
      recentHeader: [
        { text: this.$t('student.student_headers.headers1'), value: "name" },
        {
          text: this.$t('admin.admin_ultimoacesso'),
          value: "date",
          align: "center",
        },
        {
          text: this.$t('admin.admin_horario'),
          value: "hour",
          align: "center",
        },
        {
          text: this.$t('regisResponsavel.painel.escola'),
          value: "School",
          align: "center",
        },
      ],
      activeStudents: [],
      recentLoginsData: [],
      allRecentLoginsData: [],
      allLogins: false,
    };
  },
  created() {
    this.getDate(new Date());
    this.getActiveStudents();
    this.getRecentAccess();
  },
  methods: {
    formateDate(month, year) {
      let monthNames = [
        { full: this.$t('admin.admin_calendario.Janeiro'), abb: this.$t('admin.admin_calendarioabb.Janeiro') },
        { full: this.$t('admin.admin_calendario.Fevereiro'), abb: this.$t('admin.admin_calendarioabb.Fevereiro') },
        { full: this.$t('admin.admin_calendario.Março'), abb: this.$t('admin.admin_calendarioabb.Março') },
        { full: this.$t('admin.admin_calendario.Abril'), abb: this.$t('admin.admin_calendarioabb.Abril') },
        { full: this.$t('admin.admin_calendario.Maio'), abb: this.$t('admin.admin_calendarioabb.Maio') },
        { full: this.$t('admin.admin_calendario.Junho'), abb: this.$t('admin.admin_calendarioabb.Junho') },
        { full: this.$t('admin.admin_calendario.Julho'), abb: this.$t('admin.admin_calendarioabb.Julho') },
        { full: this.$t('admin.admin_calendario.Agosto'), abb: this.$t('admin.admin_calendarioabb.Agosto') },
        { full: this.$t('admin.admin_calendario.Setembro'), abb: this.$t('admin.admin_calendarioabb.Setembro') },
        { full: this.$t('admin.admin_calendario.Outubro'), abb: this.$t('admin.admin_calendarioabb.Outubro') },
        { full: this.$t('admin.admin_calendario.Novembro'), abb: this.$t('admin.admin_calendarioabb.Novembro') },
        { full: this.$t('admin.admin_calendario.Dezembro'), abb: this.$t('admin.admin_calendarioabb.Dezembro') },
      ];
      this.currentMonth = monthNames[month - 1].full;
      this.formatedDate = this.currentMonth + ", " + year;
    },
    getDate(date) {
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let year = date.getFullYear();
      this.formateDate(date.getMonth(), year);

      this.selectDate(year + "-" + month, year);
    },
    selectDate(date) {
      this.dateForFilter = date;

      const [year, month] = date.split("-");
      let monthStr = parseInt(month).toString();
      this.formateDate(monthStr, year);
      this.getChartData(date);
      setTimeout(() => {
        this.dataPicker = false;
      }, 100);
    },
    async getChartData(date) {
      try {
        let res = await axios.get(
          `${url}/access/userType/forClient`,

          authorization
        );

        let response = await this.filterDataByMonth(res.data.result, date);

        this.chartData = { name: [], data: [[], [], []] };
        for (let index = 0; index < response.length; index++) {
          const { school, school_count, student_count, teacher_count } =
            response[index];
          this.chartData.name.push(school);
          this.chartData.data[0].push(school_count);
          this.chartData.data[1].push(teacher_count);
          this.chartData.data[2].push(student_count);
        }

        this.getTotals(this.chartData.data);
        this.$refs.userChart.updateData();
      } catch (error) {
        console.error(error);
      }
    },
    getTotals(data) {
      const sums = data.map((arr) => this.sumValues(arr));
      this.totalCards[1].value = sums[0];
      this.totalCards[2].value = sums[1];
      this.totalCards[3].value = sums[2];
      this.totalCards[0].value = sums.reduce((acc, val) => acc + val);
    },
    filterDataByMonth(data, date) {
      return data.filter((obj) => obj.month === date);
    },

    sumValues(accessNumbers) {
      let valuesArray = Object.values(accessNumbers);
      return valuesArray.reduce((acc, val) => acc + val, 0);
    },
    async getActiveStudents() {
      try {
        let res = await axios.get(
          `${url}/access/studentActive/forClient`,
          authorization
        );

        this.activeStudents = res.data.result;
        const { totalStudentsAcceptedTerms, totalStudents } = this.sumTotals(
          res.data.result
        );

        this.activeCards[0].value = totalStudents;
        this.activeCards[1].value = totalStudentsAcceptedTerms;
      } catch (error) {
        console.error(error);
      }
    },
    sumTotals(data) {
      let totalStudentsAcceptedTerms = 0;
      let totalStudents = 0;

      for (let i = 0; i < data.length; i++) {
        totalStudentsAcceptedTerms += data[i].total_students_accepted_terms;
        totalStudents += data[i].total_students;
      }

      return { totalStudentsAcceptedTerms, totalStudents };
    },
    async getRecentAccess() {
      try {
        let response = await axios.get(
          `${url}/access/studentRecent/forClient`,
          authorization
        );

        this.activeCards[2].value = this.countTodayAccess(response.data.result);

        this.allRecentLoginsData = response.data.result;
        this.recentLoginsData = await this.getDistinctRecentNames(
          response.data.result
        );
      } catch (error) {
        console.error(error);
      }
    },
    countTodayAccess(data) {
      const today = new Date().toLocaleDateString("pt-BR", {
        timeZone: "America/Fortaleza",
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });

      let count = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i].date === today) {
          count++;
        }
      }
      return count;
    },
    getDistinctRecentNames(entries) {
      const mostRecentEntries = {};

      entries.forEach((entry) => {
        const { name, date, hour, School } = entry;

        const [day, month, year] = date.split("/");
        const [hours, minutes] = hour.split(":");
        const dateObj = new Date(
          parseInt(year),
          parseInt(month) - 1,
          parseInt(day),
          parseInt(hours),
          parseInt(minutes)
        );

        if (Object.prototype.hasOwnProperty.call(mostRecentEntries, name)) {
          if (dateObj.getTime() > mostRecentEntries[name].dateObj.getTime()) {
            mostRecentEntries[name] = {
              dateObj,
              date,
              hour,
              School,
            };
          }
        } else {
          mostRecentEntries[name] = {
            dateObj,
            date,
            hour,
            School,
          };
        }
      });

      return Object.keys(mostRecentEntries).map((name) => ({
        name,
        date: mostRecentEntries[name].date,
        hour: mostRecentEntries[name].hour,
        School: mostRecentEntries[name].School,
      }));
    },
  },
};
</script>

<style scoped>
.pink-bg {
  background-color: #ff00ff2a;
}
.cyan-bg {
  background-color: #00ccff2a;
}
.yellow-bg {
  background-color: #ffe60031;
}
.blue-bg {
  background-color: #0000ff1c;
}
</style>
