<template>
  <div>
    <v-toolbar dense class="elevation-1">
      <v-icon color="orange" class="text-h4">mdi-school</v-icon>
      <v-toolbar-title
        :style="
          $vuetify.breakpoint.smAndUp
            ? 'letter-spacing: 0.5rem'
            : 'letter-spacing: 0.2rem'
        "
        class="ml-3 font-weight-bold"
        >{{ $t('school.school_titleadmin') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-dialog
        v-model="registerDialog"
        persistent
        max-width="70%"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded
            color="darkpink"
            dark
            v-bind="attrs"
            v-on="on"
            @click="registerDialog = true"
          >
            <v-icon left> mdi-plus-thick </v-icon>
            {{ $t('botoes.botao_novo') }}
          </v-btn>
        </template>
        <v-card class="rounded-lg">
          <v-card-title class="darkblue white--text">
            <span class="text-h5">{{ $t('botoes.botao_novoescola') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="schoolForm">
                <v-row>
                  <v-col cols="12" sm="7" md="8">
                    <v-text-field
                      color="blue"
                      :rules="[rules.required]"
                      v-model="formSchool.name"
                      :label="$t('label.label_nomeempresarial')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="5" md="4">
                    <v-text-field
                      color="blue"
                      v-model="formSchool.nickname"
                      :label="$t('label.label_nomefantasia')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="8" sm="4" md="6">
                    <v-text-field
                      v-model="formSchool.user"
                      :label="$t('label.label_usuario')"
                      color="blue"
                      @change="verifyUser(formClient.username)"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="8" sm="4" md="6" v-if="!editMode">
                    <v-text-field
                      v-model="formSchool.password"
                      :label="$t('label.label_senha.label_senhaob')"
                      color="blue"
                      :rules="[rules.required, rules.length(4)]"
                      :type="showPassword ? 'text' : 'password'"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" sm="5" md="6">
                    <v-autocomplete
                      color="blue"
                      :rules="[rules.required]"
                      v-model="formSchool.country"
                      :items="countries"
                      item-color="darkblue"
                      :label="$t('label.label_pais')"
                      @change="getStates(formSchool.country)"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4" sm="3" md="2">
                    <v-autocomplete
                      color="blue"
                      :rules="[rules.required]"
                      v-model="formSchool.state"
                      :items="states"
                      :item-text="
                        formSchool.country === 'Brazil' ? 'state_code' : 'name'
                      "
                      item-value="name"
                      item-color="darkblue"
                      no-data-text="Selecione o País"
                      :label="
                        formSchool.country == 'Paraguay'
                          ? 'Departamento*'
                          : 'Estado*'
                      "
                      @change="getCities(formSchool.state)"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="8" sm="4" md="4">
                    <v-autocomplete
                      color="blue"
                      :rules="[rules.required]"
                      v-model="formSchool.city"
                      :items="cities"
                      item-color="darkblue"
                      :no-data-text="
                        formSchool.country == 'Paraguay'
                          ? 'Selecione o Departamento'
                          : 'Selecione o Estado'
                      "
                      label="Cidade*"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4" sm="3" md="2">
                    <v-text-field
                      v-model="formSchool.zipCode"
                      label="CEP*"
                      color="blue"
                      :rules="[rules.required]"
                      @blur="consultCep()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="8" sm="7" md="6">
                    <v-text-field
                      v-model="formSchool.street"
                      label="Rua*"
                      color="blue"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="2" md="1">
                    <v-text-field
                      v-model="formSchool.buildingNumber"
                      label="Nº"
                      color="blue"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="8" sm="4" md="3">
                    <v-text-field
                      v-model="formSchool.district"
                      label="Bairro*"
                      color="blue"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="4" md="6">
                    <v-select
                      v-model="formSchool.adminDependency"
                      :items="['Municipal', 'Estadual', 'Federal']"
                      item-color="darkblue"
                      label="Rede de Ensino*"
                      color="blue"
                      :rules="[rules.required]"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6" sm="4" md="6">
                    <v-autocomplete
                      color="blue"
                      v-model="gradeSelected"
                      :items="gradeOptions"
                      item-color="darkblue"
                      item-text="name"
                      item-value="reduce"
                      label="Nível de ensino"
                      multiple
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="5" md="6">
                    <v-checkbox
                      color="blue"
                      v-model="formSchool.licensed"
                      item-color="darkblue"
                      label="Licenciado"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>*{{ $t('regras.regra_obrigatorio') }}</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darkpink" rounded text @click="cancel()">
              {{ $t('botoes.botao_cancelar') }}
            </v-btn>
            <v-btn color="darkpink" rounded text @click="saveBtn()">
              {{ $t('botoes.botao_salva') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card
        class="rounded-lg py-3 error--text"
        style="border-left: 12px inset red"
      >
        <v-card-title>
          <v-icon left large color="error">mdi-alert-circle</v-icon>
          {{ $t('alerta.alerta_title') }}
        </v-card-title>
        <v-card-text class="black--text">
          {{ $t('alerta.alerta_excluir.excluir1') }}
        </v-card-text>
        <v-card-actions class="d-flex justify-end pr-4">
          <v-btn
            color="error"
            dark
            rounded
            class="px-3"
            @click="deleteItemConfirm()"
          >
            {{ $t('botoes.botao_excluir') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="elevation-0 mt-1">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          color="blue"
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
          :style="
            $vuetify.breakpoint.smAndUp ? 'max-width: 30%' : 'max-width: 60%'
          "
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="schools"
        :search="search"
        :footer-props="{
          'items-per-page-options': [20, 45, 60, 100, -1],
          'items-per-page-all-text': $t('events.events_headers.headers1'),
          'items-per-page-text': $t('student.student_paginas'),
          'show-first-last-page': true,
        }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            small
            outlined
            rounded
            color="darkpink"
            class="px-2 mx-1"
            @click="addOpen(item)"
          >
            <v-icon small class="mr-1">mdi-folder-open </v-icon>
            {{ $t('admin.admin_menu.menu2') }}
          </v-btn>
          <v-btn
            small
            outlined
            rounded
            color="darkblue"
            class="px-2 mx-1"
            @click="editItem(item)"
          >
            <v-icon small class="mr-1"> mdi-pencil </v-icon>
            {{ $t('botoes.botao_edita') }}
          </v-btn>
          <v-btn
            small
            outlined
            rounded
            color="error"
            class="px-2 mx-1"
            @click="deleteItem(item)"
          >
            <v-icon small class="mr-1"> mdi-delete </v-icon>
            {{ $t('botoes.botao_excluir') }}
          </v-btn>
        </template>
        <template v-slot:[`item.stages`]="{ item }">
          <p v-if="item.stages.preEscola">Pré-escola</p>
          <p v-if="item.stages.infantil">Educação Infantil"</p>
          <p v-if="item.stages.fundamental1">Ensino Fundamental 1</p>
          <p v-if="item.stages.fundamental2">Ensino Fundamental 2</p>
          <p v-if="item.stages.medio">Ensino Médio</p>
          <p v-if="item.stages.medioTec">Ensino Médio Técnico</p>
          <p v-if="item.stages.EJA">Educação de Jovens e Adultos (EJA)</p>
          <p v-if="item.stages.especial">Educação Especial</p>
          <p v-if="item.stages.superior">Educação Superior</p>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="dialogAddCourse"
      :width="$vuetify.breakpoint.mdAndUp ? '45%' : '90%'"
      persistent
    >
      <v-card class="rounded-lg">
        <v-card-title class="text-h5 darkblue white--text"
          >{{ $t('admin.admin_permissaocurso') }}</v-card-title
        >

        <v-card-text class="mt-6">
          <!-- <v-row>
              <v-col cols="12" sm="6" v-for="cour in courses" :key="cour.id">
                <v-row>
                  <v-checkbox
                    :label="cour.name"
                    color="darkblue"
                    :value="cour.id"
                  >
                  </v-checkbox>
  
                  <v-avatar right class="ml-2" size="60">
                    <v-img :src="cour.thumbnail"></v-img>
                  </v-avatar>
                </v-row>
              </v-col>
            </v-row> -->

          <v-autocomplete
            v-model="friends"
            :items="courses"
            class="my-4"
            chips
            item-color="darkblue"
            color="blue"
            :label="$t('label.label_permissao')"
            :no-data-text="$t('admin.admin_nenhumcurso')"
            item-text="name"
            item-value="name"
            :placeholder="$t('admin.admin_selecionecurso')"
            multiple
          >
            <template v-slot:selection="data">
              <v-chip
                active-class="blue--text text--accent-4"
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="remove(data.item)"
              >
                <v-avatar left>
                  <v-img :src="data.item.thumbnail"></v-img>
                </v-avatar>
                {{ data.item.name }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darkpink" text @click="closeAdd()"> {{ $t('botoes.botao_cancelar') }} </v-btn>
          <v-btn color="darkpink" text @click="savePermission()">
            {{ $t('botoes.botao_salva') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-snackbar v-model="snackbar" :timeout="timeout">
        
  
        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar> -->
    <v-snackbar
      v-model="successSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="success"
      outlined
      text
    >
      <v-icon large color="success" class="mr-2">mdi-check-circle </v-icon>
      <span class="subtitle-1 black--text">
        {{ $t('alerta.alerta_sucesso') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="successSnackbar = false"
          rounded
          color="success"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="error"
      outlined
      text
    >
      <v-icon large color="error" class="mr-2">mdi-close-circle </v-icon>
      <span class="subtitle-1 black--text">
        {{ $t('alerta.alerta_erro') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="errorSnackbar = null"
          rounded
          color="error"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// import { parseMask } from VueMask;

const url = process.env.VUE_APP_API_URL;
const axios = require("axios");
const token = "Baerer " + localStorage.getItem("accessToken");

export default {
  data() {
    return {
      showPassword: false,
      registerDialog: false,
      formSchool: {},
      countries: ["Brazil", "Paraguay"],
      country: null,
      states: [],
      state: null,
      cities: [],
      city: null,
      gradeOptions: [
        { name: "Pré-escola", reduce: "preEscola" },
        { name: "Educação Infantil", reduce: "infantil" },
        { name: "Ensino Fundamental 1", reduce: "fundamental1" },
        { name: "Ensino Fundamental 2", reduce: "fundamental2" },
        { name: "Ensino Médio", reduce: "medio" },
        { name: "Ensino Médio Técnico", reduce: "medioTec" },
        { name: "Educação de Jovens e Adultos (EJA)", reduce: "EJA" },
        { name: "Educação Especial", reduce: "especial" },
        { name: "Educação Superior", reduce: "superior" },
      ],
      rules: {
        email: (v) =>
          !!(v || "").match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        maxLength: (len) => (v) =>
          (v || "").length <= len ||
          `${this.$t('regras.regra_senhaMax.senha1')} ${len} ${this.$t('regras.regra_senhaMax.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        user: (v) =>
          !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
          `${this.$t('regras.regra_user')}`,
        exist: () => !this.userExisting || this.$t('regras.regra_existeuser'),

        none: true,
      },
      gradeSelected: [],
      coursesSelected: [],
      schools: [],
      search: "",
      headers: [
        { text: this.$t('regisResponsavel.painel.escola'), value: "name" },
        { text: this.$t('login.login_painel.login1'), value: "user" },
        { text: this.$t('admin.admin_sort.sort2'), value: "city" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      editedIndex: -1,
      dialogDelete: null,
      editMode: false,
      dialogAddCourse: null,
      courses: [],
      friends: [],
      snackbar: false,
      successSnackbar: false,
      errorSnackbar: false,
      timeout: 2000,
      cepError: false,
      idNewSchool: null,
      userExisting: false,
    };
  },
  methods: {
    async verifyUser(res) {
      let response = await axios.get(`${url}/clients/user?user=${res}`, {
        headers: {
          authorization: token,
        },
      });
      if (response.data != null) {
        this.userExisting = true;
      } else {
        this.userExisting = false;
      }
    },
    closeAdd() {
      this.friends = [];
      this.dialogAddCourse = false;
    },
    addOpen(item) {
      this.friends = [];
      this.editedIndex = item.id;
      this.dialogAddCourse = true;
      axios
        .get(`${url}/courseInscriptions/?school=${item.id}`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          res.data.forEach((element) => {
            this.friends.push(element.Course.name);
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async consultCep() {
      try {
        let consulta = await axios.get(
          `https://viacep.com.br/ws/${this.formSchool.zipCode}/json/?callback=`
        );

        if (!consulta.data.erro) {
          this.formSchool.street = consulta.data.logradouro;
          this.formSchool.district = consulta.data.bairro;
        }
      } catch {
        this.cepError = true;
      }
    },
    savePermission() {
      let coursesIds = [];
      for (let i = 0; i < this.friends.length; i++) {
        for (let j = 0; j < this.courses.length; j++) {
          if (this.friends[i] == this.courses[j].name) {
            coursesIds.push(this.courses[j].id);
          }
        }
      }
      const data = { schoolId: this.editedIndex, courseId: coursesIds };
      axios
        .post(`${url}/courseInscriptions`, data, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.closeAdd();
          this.snackbar = true;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    remove(item) {
      const index = this.friends.indexOf(item.name);
      if (index >= 0) this.friends.splice(index, 1);
    },
    getcourses() {
      axios
        .get(`${url}/clientCoursePermissions`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          res.data.forEach((element) => {
            this.courses.push(element.Course);
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },

    getStates(c) {
      var body = { country: c };
      axios
        .post("https://countriesnow.space/api/v0.1/countries/states", body)
        .then((res) => {
          this.states = res.data.data.states;
          this.formSchool.state = null;
          this.formSchool.city = null;
        });
    },
    getCities(s) {
      var body = { country: this.formSchool.country, state: s };
      axios
        .post(
          "https://countriesnow.space/api/v0.1/countries/state/cities",
          body
        )
        .then((res) => {
          this.cities = res.data.data;
        });
    },
    registerSchool() {
      let grade = {
        preEscola: false,
        infantil: false,
        fundamental1: false,
        fundamental2: false,
        medio: false,
        medioTec: false,
        EJA: false,
        especial: false,
        superior: false,
      };
      this.gradeSelected.forEach((element) => {
        grade[`${element}`] = true;
      });
      this.formSchool.stages = grade;

      axios
        .post(`${url}/schoolsForClient`, this.formSchool, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.getSchools();
          this.idNewSchool = res.data.success.id;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    cancel() {
      this.registerDialog = false;
      this.editMode = false;
      this.$refs.schoolForm.reset();
    },
    getSchools() {
      axios
        .get(`${url}/schoolsForClient`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.schools = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    deleteItem(item) {
      this.editedIndex = item.id;
      this.formSchool = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      axios
        .delete(`${url}/schools/${this.editedIndex}`, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.closeDelete();
          this.getSchools();
          this.$refs.schoolForm.reset();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.formSchool = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    editItem(item) {
      Object.keys(item.stages).forEach((element) => {
        if (item.stages[element]) {
          this.gradeSelected.push(element);
        }
      });

      this.editedIndex = item.id;
      this.cities = [item.city];
      this.states = [item.state];
      this.formSchool = Object.assign({}, item);
      this.registerDialog = true;
      this.editMode = true;
    },

    updateSchool() {
      let grade = {
        preEscola: false,
        infantil: false,
        fundamental1: false,
        fundamental2: false,
        medio: false,
        medioTec: false,
        EJA: false,
        especial: false,
        superior: false,
      };
      this.gradeSelected.forEach((element) => {
        grade[`${element}`] = true;
      });
      this.formSchool.stages = grade;

      axios
        .put(`${url}/schools/${this.editedIndex}`, this.formSchool, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.getSchools();

          setTimeout(() => {
            this.successSnackbar = true;
          }, 1000);
        })
        .catch((error) => {
          console.error(error);
          alert("Error");
        });
    },
    async saveBtn() {
      let validation = this.$refs.schoolForm.validate();

      if (validation) {
        if (!this.editMode) {
          try {
            await this.registerSchool();
            this.editMode = false;
            setTimeout(() => {
              this.successSnackbar = true;
            }, 1000);
          } catch {
            this.errorSnackbar = true;
          }
        }
        if (this.editMode) {
          try {
            await this.updateSchool();
            this.editMode = false;
          } catch {
            this.errorSnackbar = true;
          }
        }
        this.$refs.schoolForm.reset();
        this.registerDialog = false;
      }
    },
    // getRawValue(value) {
    //   const rawValueArray = [];
    //   let mask = "#####-###";
    //   mask.split("").forEach((character, i) => {
    //     if (character === "#") {
    //       rawValueArray.push(value[i]);
    //     }
    //   });

    //   return rawValueArray.join("");
    // },
  },
  mounted() {},
  created() {
    this.getSchools();
    this.getcourses();
  },
};
</script>

<style scoped></style>
