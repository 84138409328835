<template>
  <div>
    <v-toolbar dense class="elevation-1">
      <v-icon color="green" class="text-h4">mdi-account-school</v-icon>
      <v-toolbar-title
        style="letter-spacing: 0.5rem"
        class="ml-3 font-weight-bold"
        >{{ $t('student.student_title') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-dialog v-model="listAulasOn" max-width="800px">
        <v-card class="rounded-lg">
          <v-card-title class="darkblue white--text">
            <span class="text-h5">{{ $t('school.school_qtd.aulasaplica') }}</span>
          </v-card-title>
          <v-card-text>
            <v-card-text
              class="text-center text-h6 black--text"
              v-if="semAulasP == true"
            >
              <v-img
                max-width="200px"
                class="mx-auto"
                src="../../assets/OnPencil.svg"
              />
              <span class="mt-6">
                {{ $t('school.school_nenhumaplicado') }}
              </span>
            </v-card-text>
            <v-container v-if="resuu == true">
              <v-data-table
                :headers="headersDasAulas"
                :items="appliedClassItem"
                :footer-props="{
                  'items-per-page-options': [20, 45, 60, 100, -1],
                  'items-per-page-all-text': $t('events.events_headers.headers1'),
                  'items-per-page-text': $t('student.student_paginas'),
                  'show-first-last-page': true,
                }"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                    rounded
                    outlined
                    small
                    @click="openModalActivis(item)"
                    color="darkpink"
                    class="px-2 mx-1"
                  >
                    <v-icon small class="mr-1">mdi-book</v-icon>
                    {{ $t('admin.admin_menu.menu19') }}
                  </v-btn>
                </template>
              </v-data-table>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darkpink" rounded text @click="closeAulasContainer()">
              {{ $t('botoes.botao_sair') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <!-- <v-card class="rounded-lg">
          <v-card-title class="black white--text">
            <span class="text-h5">Aulas extras</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              aqui vão as aulas extras
            </v-container>
          </v-card-text>
         
        </v-card> -->
      </v-dialog>

      <v-dialog v-model="listOn" max-width="800px">
        <v-card class="rounded-lg">
          <v-card-title class="darkblue white--text">
            <span class="text-h5">{{ $t('admin.admin_menu.menu19') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-data-table
                :headers="headersDasAtividades"
                :items="appliedAtividadeItem"
                :footer-props="{
                  'items-per-page-options': [20, 45, 60, 100, -1],
                  'items-per-page-all-text': $t('events.events_headers.headers1'),
                  'items-per-page-text': $t('student.student_paginas'),
                  'show-first-last-page': true,
                }"
              >
              </v-data-table>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darkpink" rounded text @click="closeAtividade()">
              {{ $t('botoes.botao_sair') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog" persistent max-width="600px">
        <!-- <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="darkpink"
            rounded
            dark
            v-bind="attrs"
            v-on="on"
            @click="formStudent = {}"
          >
            <v-icon left> mdi-plus-thick </v-icon>
            Novo
          </v-btn>
        </template> -->
        <v-card class="rounded-lg">
          <v-card-title class="darkblue white--text">
            <span class="text-h5">{{ $t('botoes.botao_novoaluno') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      color="blue"
                      v-model="formStudent.name"
                      :rules="[rules.required]"
                      :label="$t('label.label_nome')"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      color="blue"
                      v-model="formStudent.user"
                      :rules="[rules.required]"
                      :label="$t('label.label_usuario')"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      color="blue"
                      :label="$t('label.label_email.email_obr')"
                      :rules="[rules.required, rules.email]"
                      v-model="formStudent.email"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-select
                      color="blue"
                      v-model="formStudent.grade"
                      :items="grades"
                      :rules="[rules.required]"
                      label="Grade*"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      color="blue"
                      v-model="formStudent.teamId"
                      :items="turmas"
                      :rules="[rules.required]"
                      :label="$t('label.label_turma')"
                      item-text="name"
                      item-value="id"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      color="blue"
                      v-model="formStudent.password"
                      :rules="[rules.required, rules.length(4)]"
                      :label="$t('label.label_senha.label_senhaob')"
                      type="password"
                      v-if="!editMode"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>*{{ $t('regras.regra_obrigatorio') }}</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darkpink" rounded text @click="closeRegister()">
              {{ $t('botoes.botao_cancelar') }}
            </v-btn>
            <v-btn
              color="darkpink"
              rounded
              text
              @click="
                editMode ? updateStudent(formStudent.id) : registerStudent()
              "
            >
              {{ $t('botoes.botao_salva') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-dialog v-model="dialogDelete" max-width="550px">
      <v-card
        class="rounded-lg py-3 error--text"
        style="border-left: 12px inset red"
      >
        <v-card-title>
          <v-icon left large color="error">mdi-alert-circle</v-icon>
          {{ $t('alerta.alerta_title') }}
        </v-card-title>
        <v-card-text class="black--text">
          {{ $t('alerta.alerta_excluir.excluir1') }}
        </v-card-text>
        <v-card-actions class="d-flex justify-end pr-4">
          <v-btn
            color="error"
            dark
            rounded
            class="px-3"
            @click="deleteItemConfirm"
          >
            {{ $t('botoes.botao_excluir') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="elevation-0 mt-1">
      <v-card-title>
        <span v-if="$vuetify.breakpoint.smAndUp">Alunos</span>
        <v-spacer></v-spacer>
        <v-text-field
          color="blue"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$vuetify.breakpoint.smAndUp ? $t('label.label_pesquisa') : ''"
          single-line
          hide-details
          :style="
            $vuetify.breakpoint.smAndUp ? 'max-width: 30%' : 'max-width: 60%'
          "
        >
        </v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="Students"
        :search="search"
        :footer-props="{
          'items-per-page-options': [20, 45, 60, 100, -1],
          'items-per-page-all-text': $t('events.events_headers.headers1'),
          'items-per-page-text': $t('student.student_paginas'),
          'show-first-last-page': true,
        }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            rounded
            outlined
            small
            @click="onAulasList(item)"
            color="darkpink"
            class="px-2 mx-1"
          >
            <v-icon small class="mr-1">mdi-television-play</v-icon>
            {{ $t('admin.admin_menu.menu18') }}
          </v-btn>

          <!-- <v-btn
            rounded
            outlined
            small
          @click="editItem(item)"
            color="darkblue"
            class="px-2 mx-1"
          >
            <v-icon small class="mr-1">mdi-pencil</v-icon>
            Editar
          </v-btn> -->
          <!-- <v-btn
            rounded
            outlined
            small
            @click="deleteItem(item)"
            color="error"
            class="px-2 mx-1"
          >
            <v-icon small class="mr-1">mdi-delete</v-icon>
            Excluir
          </v-btn> -->
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<style scoped></style>

<script>
const url = process.env.VUE_APP_API_URL;
const axios = require("axios");
const token = "Baerer " + localStorage.getItem("accessToken");
export default {
  data() {
    return {
      resuu: false,
      semAulasP: false,
      listAulasOn: null,
      listOn: null,
      dialog: null,
      formStudent: {},
      search: "",
      headers: [
        { text: this.$t('student.student_headers.headers1'), value: "name" },
        { text: this.$t('label.label_email.email'), value: "email" },
        { text: this.$t('regisResponsavel.painel.escola'), value: "school" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      headersDasAulas: [
        { text: this.$t('admin.admin_menu.menu18'), value: "title" },
        { text: this.$t('school.school_curso'), value: "course" },
        { text: this.$t('school.school_headers.heardes8'), value: "description" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      headersDasAtividades: [
        { text: this.$t('admin.admin_dasatividades.header1'), value: "content" },
        { text: this.$t('admin.admin_dasatividades.header2'), value: "correct" },
      ],
      appliedClassItem: [],
      appliedAtividadeItem: [],
      Students: [],
      editedIndex: -1,
      dialogDelete: null,
      editMode: false,
      rules: {
        email: (v) =>
          !!(v || "").match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        maxLength: (len) => (v) =>
          (v || "").length <= len ||
          `${this.$t('regras.regra_senhaMax.senha1')} ${len} ${this.$t('regras.regra_senhaMax.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        user: (v) =>
          !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
          `${this.$t('regras.regra_user')}`,
        none: true,
      },
      nameSchool: null,
      schoolName: [],
      schools: [],
      turmas: [],
      grades: [
        "1° Infantil",
        "2° Infantil",
        "3° Infantil",
        "4° Infantil",
        "1° Fundamental 1",
        "2° Fundamental 1",
        "3° Fundamental 1",
        "4° Fundamental 1",
        "5° Fundamental 1",
        "6° Fundamental 2",
        "7° Fundamental 2",
        "8° Fundamental 2",
        "9° Fundamental 2",
        "1° Médio",
        "2° Médio",
        "3° Médio",
      ],
      // IDdaEscola: 8 //dps pegar o id correto do localStorage
    };
  },
  methods: {
    checkStudentLink() {
      for (let i = 0; i < this.Students.length; i++) {
        axios
          .get(`${url}/studentLinkTeams/?student=${this.Students[i].id}`, {
            headers: {
              authorization: token,
            },
          })
          .then((res) => {
            if (res.data.length > 0) {
              this.Students[i].checked = true;
            } else {
              this.Students[i].checked = false;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    // getTeamsByGrade(grade) {
    //   axios
    //     .get(
    //       `${url}/teamByGradeSchool/?grade=${grade}`, {
    //       headers: {
    //         authorization: token,
    //       },
    //     })
    //     .then((res) => {
    //       console.log(res);
    //       this.turmas = res.data;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    getStudent() {
      axios
        .get(`${url}/studentsBySchoolClient`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.Students = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async apleedByActividadClient(item) {
      try {
        let res = await axios.get(
          `${url}/appliedClassesAttendedClient?student=${item.id}`,
          {
            headers: {
              authorization: token,
            },
          }
        );

        if (res.data.length == 0) {
          this.resuu = false;
          this.semAulasP = true;
        } else {
          this.semAulasP = false;
          this.resuu = true;
          this.appliedClassItem = res.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async trueOrFalseActividade(item) {
      try {
        let res = await axios.get(
          `${url}/studentAnswersByStudent?student=${item.idStudent}&lesson=${item.id}`,
          {
            headers: {
              authorization: token,
            },
          }
        );

        this.appliedAtividadeItem = res.data;
        this.appliedAtividadeItem.map((res) => {
          if (res.correct == 1) {
            res.correct = this.$t('school.school_corecao.corecao2');
          } else {
            res.correct = this.$t('school.school_corecao.corecao1');
          }
          return res.correct;
        });
      } catch (error) {
        console.error(error);
      }
    },
    getTeamsBySchool() {
      axios
        .get(`${url}/teamsForSchool`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.turmas = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getSchools() {
      axios
        .get(`${url}/schools`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.schools = res.data;
        });
    },
    registerStudent() {
      this.$refs.form.validate();
      this.formStudent.schoolId = this.IDdaEscola;

      axios
        .post(`${url}/students`, this.formStudent, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.closeRegister();
          this.getStudent();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateStudent(id) {
      axios
        .put(`${url}/students/${id}`, this.formStudent, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.closeRegister();
          this.getStudent();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    deleteItemConfirm() {
      axios
        .delete(`${url}/students/${this.editedIndex}`, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.closeDelete();
          this.getStudent();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    closeRegister() {
      this.dialog = false;
      this.formStudent = {};
      this.editMode = false;
      this.$refs.form.resetValidation();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.formStudent = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = item.id;
      this.formStudent = Object.assign({}, item);
      this.dialogDelete = true;
    },
    editItem(item) {
      this.editedIndex = item.id;
      this.formStudent = Object.assign({}, item);
      this.dialog = true;
      this.editMode = true;
    },
    atividade(item) {
      this.editedIndex = item.id;
      this.listOn = true;
    },
    closeAtividade() {
      this.listOn = false;
    },
    onAulasList(item) {
      this.apleedByActividadClient(item);
      this.editedIndex = item.id;
      this.listAulasOn = true;
    },
    openModalActivis(item) {
      this.trueOrFalseActividade(item);
      this.editedIndex = item.id;
      this.listOn = true;
    },
    closeAulasContainer() {
      this.listAulasOn = false;
    },
    searchByTeam() {
      this.search = localStorage.getItem("teamSingular");
      localStorage.removeItem("teamSingular");
    },
  },
  created() {
    this.getStudent();
    this.getSchools();
    this.getTeamsBySchool();
    this.searchByTeam();
  },
};
</script>
